import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, sprintf } from "@/libs/utilities"
import { ICritery } from "../../data/interfaces"
import { useState } from "react"
import { Callout, Collapse, Intent } from "@blueprintjs/core"
import { isUser } from "@/settings/zustand/user"
import { useParams } from "react-router"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"

const ERROR_TEXT = "write a message"
/* Protopia Ecosystem component */
export interface IOffestAttachmentBtnProps {
   item: ICritery
}
const OffestAttachmentBtn = (props: IOffestAttachmentBtnProps): JSX.Element => {
    const {landId} = useParams()
    const [isLoad, setIsLoad] = useState<boolean>(false)
    const [text, setText] = useState<string>("")
    const [descr, setDescr] = useState<string>("")

    const onText = (value: string) => {
        setText(value) 
        setDescr("")
    }
    const sendMessage = () => {
        if(!text.length) {
            setDescr(ERROR_TEXT)
            return
        }
        setIsLoad(true)
        actions(UPDATE_SINGLE_TYPE, { 
            data_type: "PEMessage", 
            id:  "-1", 
            item: {
                title: `${ sprintf( __("Critery's %s offer: "), props.item.title)} ${text}`,
                post_content: "",
                is_viewed: true,
                resource_id: props.item.id,
                resource_type: "PEFestCritery",
                resource_title: props.item.title
            }, 
            landId
        })
        .then(() => {
            setIsLoad(false)
        })
    }
    const onDesmiss = () => {
        setDescr("")
    }
    return  isUser()
        ?
        <ConfirmButton
            buttonClassName='attachment-card'
            buttonMinimal
            buttonLoading={isLoad}
            buttonLabel={__( "Suggest your option" )}
            btnTitle={__( "Suggest your option" )}
            onConfirm={ sendMessage }
            onDesmiss={ onDesmiss }
            dialogClasssName={ DIALOG_SIZE.MINIMAL }
            dialogTitle={__( "Suggest your option" )}
            btnLabelName="small text-center"
        >
            <div className="p-5" >
                <FieldInput
                    type={SCALAR_TYPES.TEXT}
                    value={text}
                    onChange={onText}
                    editable
                    notClear
                    vertical={VerticalType.VERTICAL}
                    title={   __("Contact us" ) }
                    placeholder={__("Start write")}
                    commentary ={sprintf(__( "Suggest your own version of expanding the list of resources of the Criterion «%s»" ), props.item.title )}
                />
                <Collapse isOpen={!!descr.length} className="mt-1">
                    <Callout className="p-4 text-force-contrast small" intent={Intent.DANGER} icon="blank" compact>
                        {__(descr)}
                    </Callout>
                </Collapse>
            </div>    
        </ConfirmButton> 
        :
        <></>
}
export default OffestAttachmentBtn