import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __ } from "@/libs/utilities"
import { useState } from "react"
import { useParams } from "react-router"
import { ITrack } from "../../data/interfaces"
import SchoolGuardianChooseWidget from "../../widgets/SchoolGuardianChooseWidget"

/* Protopia Ecosystem component */
export interface ITrackFormProps {
   
}
const TrackForm = (props: ITrackFormProps): JSX.Element => {
    const {landId} = useParams()
    const [track, setTrack] = useState<ITrack>() 

    return <div className='small-container '>
        <div className='container  '>
            <div className=" mt-4 mb-2 text-uppercase ">
                {__("Choose Track to set Moderators")}
            </div>
            <FieldInput
                editable
                type={SCALAR_TYPES.EXTERNAL}
                isMulti={false}
                value={track}
                onChange={ setTrack }
                landId={ landId }
                component="PEFestTrack"
                placeholder="Choose Track"
            />
            {
                !!track && <SchoolGuardianChooseWidget
                    item={track.moderator}
                    parent={track}
                    thread="moderator"
                />
            } 
            {
                !!track && <SchoolGuardianChooseWidget
                    item={track.moderator2}
                    parent={track}
                    thread="moderator2"
                />
            }
        </div>
    </div>
}
export default TrackForm