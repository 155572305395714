import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { useState } from "react"
import * as XLSX from 'xlsx'

/* Protopia Ecosystem component */
export interface IFromExcelMasterProps {
   
}
const FromExcelMaster = (props: IFromExcelMasterProps): JSX.Element => {
    const [excel, setExcel] = useState<any>()

    const onLoadExcel = ( value: any, name: string, theFile: any ) => { 
        if('application/vnd.ms-excel' !== theFile.type) {
            AppToaster.show({
                message: __("Upload a file in an acceptable format")
            })
            return
        }
        setExcel( value )



        const getCellRow = (cell: string) => Number(cell.replace(/[A-z]/gi, ""));
        const getCellColumn = (cell: string) => cell.replace(/[0-9]/g, "").toUpperCase();
        const getRangeBegin = (cell: string) => cell.match(/^[^:]*/)![0];
        const getRangeEnd = (cell: string) => cell.match(/[^:]*$/)![0];
        function getSheetCellValue(sheetCell: any) {
            if (!sheetCell) {
                return undefined;
            }
            // if (sheetCell.t === "z" && _config.sheetStubs) {
            //     return null;
            // }
            return sheetCell.t === "n" || sheetCell.t === "d"
                ? 
                sheetCell.v
                : 
                (sheetCell.w && sheetCell.w.trim && sheetCell.w.trim()) || sheetCell.w;
        }
        const data = new Uint8Array(value);
        let workbook: XLSX.WorkBook = XLSX.read(data, {
            type: "array",
        })
        console.log( name )
        console.log(  theFile, theFile.type )
        console.log( workbook )
        const sheetNames: string[] = workbook.SheetNames
        sheetNames.forEach(sName => {

            const sheet: XLSX.WorkSheet = workbook.Sheets[ sName ]
            let rows: any[] = [];
            for (let cell in sheet) {
                console.log( cell )
                if ( cell === "!ref" || ( sheet[cell].v === undefined ) ) {
                    console.log( sheet[cell] )
                    continue;
                }
                const row = getCellRow(cell);
                const column = getCellColumn(cell)
                console.log(row)
                console.log(column)
            }
        })
    }
    return <div className='pe-fromexcelmaster-container'>
        <FieldInput
            editable 
            value={excel}
            onChange={ onLoadExcel }
            type={SCALAR_TYPES.UPLOAD}
            title={__("Upload Excel file")}
            vertical={VerticalType.VERTICAL}
            width={"100%"}
            height={200}
            placeholder={__("Upload Excel file")}
            limitSize={ 10000 }
            notClear
            accept="xls,xlsx"
        />
    </div>
}
export default FromExcelMaster