import { DIALOG_SIZE, IAttachment } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { ScalableDialog } from "@/libs/useful"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IAttachmentCardProps {
   item: IAttachment
}
const AttachmentCard = ( props: IAttachmentCardProps ): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onUrl = () => {
        console.log( props.item.type )
    }
    const onOpen = () => {
        console.log( isOpen )
        console.log(props.item.type)
        setIsOpen( !isOpen )
    } 

    switch(props.item.type) { 
        case SCALAR_TYPES.VK_VIDEO_ID:
        case SCALAR_TYPES.RUTUBE_ID:
        case SCALAR_TYPES.MEDIA:
            return <>
                <AttacmentThumbnail item={props.item} onClick={ onOpen } />
                <ScalableDialog
                    isOpen={isOpen}
                    dialogSize={DIALOG_SIZE.NORMAL}
                    mayFullScreen
                    hideClose={ false }
                    // title={props.item.title}
                    onClose={onOpen}
                > 
                        <div className=" h-100 ml-3 mr-15 min-height-350">
                            <FieldInput
                                type={props.item.type}
                                value={props.item.data}
                                width="100%"
                                height={"100%"}
                                rowClassName="h-100 min-height-350"
                            />
                        </div>  
                </ScalableDialog>
            </>
        case SCALAR_TYPES.URL:
        default:
            return <AttacmentThumbnail item={props.item} onClick={ onUrl } />
    }
    
}
export default AttachmentCard


interface IAttachmentThumbnailProps {
    item: IAttachment
    onClick: () => void
}
const AttacmentThumbnail = (props:IAttachmentThumbnailProps): JSX.Element => {
    return <div 
        className='attachment-card' 
        data-attachment-id={ props.item.id } 
        style={{
            backgroundImage:`url(${props.item.thumbnail})`
        }}
        title={ props.item.title }
        onClick={props.onClick}
    >
    { props.item.thumbnail !== "" ? "" : props.item.type }
    </div>
}