import { useParams } from "react-router"
import { IFestival, IMilestone } from "../../data/interfaces"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import { __ } from "@/libs/utilities"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import Milestone from "./Milestone"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IMilestonesProps {
   
}
const Milestones = (props: IMilestonesProps): JSX.Element => {
    const festival: IFestival   = useFestDataStore( (state: IFestDataStor) => state.festival )
    const {landId} = useParams()
    const milestones: IMilestone[] = useDataStore((state: any) => state.PEFestMilestone || []) 
    const [current, setCurrent] = useState<IMilestone>( 
        milestones.filter(m => festival?.currentMilestoneId === m.id)[0] ||  milestones[0] 
    )

    const setIsOpen = ( item:IMilestone ) => {
        setCurrent( item )
    }
    return festival.isMilestones 
        ?
        <div className='  light-colored2 py-1'>
            <div className="short-container pb-4">
                <div className="row justify-content-center">
                    <div className="col-md-12 d-flex justify-content-center pt-3 pb-3">
                        <div className="role_descr">
                            {__("Milestones")}
                        </div>
                    </div>

                </div>
            </div>
            <div className=" container-fluid ">
                {
                    milestones.map(m => {
                        return <Milestone item={m} key={m.id} isOpen={m.id === current.id} setIsOpen={setIsOpen} />
                    })
                }
            </div>
        </div>
        : 
        <></>
}
export default Milestones