import { DIALOG_SIZE, IPost } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __, sprintf } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { isUser } from "@/settings/zustand/user"
import { Callout, Collapse, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { useParams } from "react-router"

const ERROR_TEXT = "write a message"
/* Protopia Ecosystem component */
export interface IBanFormProps {
   item: IPost
   commentary?: string
}
const MessageForm = (props: IBanFormProps): JSX.Element => {
    const {landId} = useParams()
    const [text, setText] = useState<string>("")
    const [descr, setDescr] = useState<string>("")

    const onText = (value: string) => {
        setText(value)
        setDescr("")
    }
    const onSendBan = () => {
        if(!text.length) {
            setDescr(ERROR_TEXT)
            return
        }
        actions(UPDATE_SINGLE_TYPE, { 
            data_type: "PEMessage", 
            id:  "-1", 
            item: {
                title: text,
                post_content: "",
                is_viewed: true,
                resource_id: props.item.id,
                resource_type: "PEFestProject",
                resource_title: props.item.title
            }, 
            landId
        })
    }
    const onDesmiss = () => {
        setDescr("")
    }
    return isUser()
        ?
        <ConfirmButton
            buttonIcon="annotation"
            buttonMinimal
            buttonLabel={__("Complain")}
            onConfirm={onSendBan}
            onDesmiss={onDesmiss}
            dialogClasssName={DIALOG_SIZE.MINIMAL}
            yesLabel="Send message"
            noLabel="Close"
            yesLock={!text.length}
        >
            <>
                <div className="p-4 w-100">
                    <FieldInput
                        type={SCALAR_TYPES.TEXT}
                        value={text}
                        onChange={onText}
                        editable
                        notClear
                        vertical={VerticalType.VERTICAL}
                        title={  sprintf( __("Contact us" ), props.item.title ) }
                        placeholder={__("Start write")}
                        commentary ={__(props.commentary || "Please inform the administrators about any errors or obscene language you find on the page.")}
                    />
                    
                </div>
                <Collapse isOpen={!!descr.length} className="mt-1">
                    <Callout className="p-4 text-force-contrast small" intent={Intent.DANGER} icon="blank" compact>
                        {__(descr)}
                    </Callout>
                </Collapse>
            </>
        </ConfirmButton>
        :
        <></>
}
export default MessageForm