import { ID } from "@/libs/interfaces/layouts";
import { DISLAY_PROJECT_RESULT, ICategory, ICritery, IMilestone, IProject, IRating, RATING_TYPES } from "../interfaces";
import { useFestProjects } from "../store";
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { getCalcSingleRating } from "./getCalculateRating";

const getCategoryRating = ( category:ICategory, milestone: IMilestone | null = null ): string => {
    const project : IProject    = useFestProjects.getState().currentProject
    const criteries: ICritery[] = useDataStore.getState().PEFestCritery || []
    const criteryIDs : ID[] = criteries
        .filter((critery:ICritery) => {
            return critery.category?.id === category.id &&
                !!milestone
                    ?
                    critery.milestone.id === milestone.id
                    :
                    false
        })
        .map((critery:ICritery) => critery.id)
    let average: string= "" 
    const summ = (project.ratings || [])
        .reduce( (acc:number, rating:IRating ) => {
            return acc = acc + (criteryIDs.includes(rating.criteryId) 
                ?
                getCalcSingleRating(rating)
                :
                0
            )
        }, 0)
    switch(category.rating_type) {
        case RATING_TYPES.DISTRIBUTION_PROCENTAGE:
        case RATING_TYPES.PROCENTAGE:
            // console.log( project.ratings )
            let criterieRatings: any = {} as any

            ( Array.isArray(project.ratings) ? project.ratings : [] )
                .filter( (rating:IRating) => criteryIDs.includes( rating.criteryId ) )
                .forEach( (rating:IRating) => {
                    if( !criterieRatings[rating.criteryId] ){
                        criterieRatings[rating.criteryId] = []
                    }
                    if( !!getCalcSingleRating(rating) ) {
                        criterieRatings[rating.criteryId].push( getCalcSingleRating(rating) )
                    }
                } )
            average = Object.keys( criterieRatings )
            .map(key => {
                const critery = criteries.filter(c => c.id === key )[0]
                const averageRating = criterieRatings[key].reduce(( acc: number, n: number ) => acc += n, 0) / ( criterieRatings[key].length || 1 )
                return { critery, averageRating }
            })
            .sort(sortD)
            .map((d:any) => {
                return "<div class='d-flex align-items-center'><span class='small opacity_5 light-font mr-2'>" + d.critery?.title + "</span> <span class='title lead'>" + d.averageRating.toFixed(2) + "</span></div>"
            })            
            .join( "" )
            break
        case RATING_TYPES.INDEPENDENT_GRADE:
        default:
            switch(category.display_rating_type) {
                case DISLAY_PROJECT_RESULT.AVERAGE:
                    average = ( summ / criteryIDs.length ).toFixed(2) 
                    break
                case DISLAY_PROJECT_RESULT.SUMMAE_MILESTONES:
                case DISLAY_PROJECT_RESULT.SUMMAE:
                default:
                    console.log( summ )
                    average = Number(summ).toFixed(2) 
            }
    } 
    return average
}
export default getCategoryRating

export const getCategoryExperts = ( category:ICategory, milestone: IMilestone | null = null ): string[] => {
    const project : IProject    = useFestProjects.getState().currentProject
    const criteries: ICritery[] = useDataStore.getState().PEFestCritery || []
    const criteryIDs : ID[] = criteries
        .filter((critery:ICritery) => {
            return critery.category?.id === category.id &&
                !!milestone
                    ?
                    critery.milestone.id === milestone.id
                    :
                    false
        })
        .map((critery:ICritery) => critery.id)

    let exists: string[] = []
    const users = (project.ratings || [])
        .filter( (rating: IRating) => criteryIDs.includes( rating.criteryId ) )
        .filter( (rating: IRating) => !!rating.rating )
        .map( (rating:IRating) => {
            if( !exists.includes( rating.display_name ) ) {
                exists.push( rating.display_name  )
                return rating.display_name 
            }
            return ""
        })
        .filter( display_name => !!display_name )
    // console.log( users )
    return users
}

const sortD = (a:any, b:any): number => {
    if(a.critery?.order > b.critery.order) return 1
    if(a.critery?.order < b.critery.order) return -1
    return 0
}

