import React, { useEffect } from 'react' 
import { Outlet, useParams } from 'react-router'
import { IFestival, ILand } from '../../data/interfaces'
import { IFestDataStor, useFestDataStore } from '../../data/store'
import ContactForm from './ContactForm'
import { NavLink } from 'react-router-dom'
import { Button, Intent, Tag } from '@blueprintjs/core'
import { component_url } from '@/libs/utilities/links'
import { isRole } from '@/settings/zustand/user'
import addCSSClass from '@/libs/layout-app/utils/addCSSClass'
import removeCSSClass from '@/libs/layout-app/utils/removeCSSClass'
import { __ } from '@/libs/utilities'
import { Role } from '@/libs/interfaces/layouts'

const AboutContainer = ( ) : JSX.Element => {
    const {landId} = useParams()
    const festival: IFestival = useFestDataStore((state: IFestDataStor) => state.festival)
    const land: ILand = useFestDataStore((state: IFestDataStor) => state.site)
    useEffect(() => {
        addCSSClass(
            "land-thumbnail",
            `
            .land-thumbnail {
                background-color: ${festival.color || "#00000000" };
                background-image: url(${ land.defaultThumbnail });
            } 
            `
        )
        return () => {
            removeCSSClass( "land-thumbnail" )
        }
    }) 
    const getTitleLayout = () =>
    {  
        return <span className="single-data-type-title">
            { festival.title?.toString() } 
        </span>
    }
    const toAdmin = `${component_url("FestivalAdminListView")}/${landId}` 
    return  <div className=" w-100 ">
        <div  className="single-data-type-thumbnail land-thumbnail position-relative">
            <div className="short-container single-data-type-thumbnail-container"> 
                <div className="main-fest-contacts h-100">
                    <ContactForm />
                </div>
                <div className="main-fest-logo-cont mt-auto">
                    <div 
                        className="thumbnail main-fest-logo" 
                        style={{
                            backgroundImage: `url(${land.icon})`,
                            height: 60,
                            width: 60
                        }} 
                    />
                </div> 
                <div className="single-data-type-thumbnail-titles container ">  
                    { getTitleLayout() } 
                </div>
                {
                    !!land.viewRoles?.length && <div className="position-absolute bottom mx-auto w-100 flex-centered flew-wrap mb-2">
                            <div className="small mr-3 available-for-label-container">
                                <span className="label">
                                {
                                    __("Available only for")
                                }:    
                                </span>
                            </div>
                            {
                                land.viewRoles.map((wr: Role) => <Tag key={wr} className="px-3 py-0 mr-1 mb-1" round intent={Intent.DANGER}>{__(wr)}</Tag> )
                            }
                        </div> 
                }
                {/* <div className="w-100 text-light d-flex justify-content-between flex-sm-row flex-column align-items-center align-items-md-end">
                    <div className="">
                        <div className="main-fest-logo-cont">
                            <div 
                                className="thumbnail main-fest-logo" 
                                style={{
                                    backgroundImage: `url(${festival.logotype})`,
                                    height: 60,
                                    width: 60
                                }} 
                            />
                        </div>                
                        <div className="display-7 d-flex justify-content-md-start justify-content-center">
                            <span className=" main-fest-title ">
                                { festival.title }
                            </span>
                        </div>
                        <div className="">
                            <span className="main-fest-description mb-4 d-flex justify-content-md-start justify-content-center">
                                { festival.description }
                            </span>
                        </div>
                    </div> 
                </div> */}
                {
                    isRole(["administrator", "SuperAdmin"]) && <NavLink
                        to={toAdmin}
                        className={"position-absolute right bottom"}
                    >
                        <Button minimal icon="unlock" />
                    </NavLink>
                }
            </div>
            {
                festival.archived && <div className="mine-corner-no-verified corner-card-project pe-pennant-dark">{__("in archive")}</div>
            }
        </div>
        <Outlet />  
    </div>
} 

export default AboutContainer