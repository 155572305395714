import AttachmentCard from "@/modules/pe-basic-module/views/attachments/AttachmentCard"
import { ICritery } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import OffestAttachmentBtn from "./OffestAttachmentBtn"

/* Protopia Ecosystem component */
export interface ICriteryAttachmentsProps {
   item: ICritery
}
const CriteryAttachments = (props: ICriteryAttachmentsProps): JSX.Element => {
    return <div className='pe-criteryattachments-container d-flex flex-wrap '>
        {
            props.item.attachments?.map(att => {
                return <AttachmentCard item={att} key={att.id} />
            })
        }
        {
            !!props.item.attachments?.length && <OffestAttachmentBtn item={props.item} />
        }
    </div>
}
export default CriteryAttachments