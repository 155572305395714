import { __ } from "src/libs/utilities"
import { Button, Intent } from "@blueprintjs/core"
import { ScalableDialog } from "src/libs/useful"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { useState } from "react"

/* Protopia Ecosystem component */
export interface IExtendedUtilFormProps {
   util: any
}
const ExtendedUtilForm = (props: IExtendedUtilFormProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    return  <>
        <Button minimal fill onClick={onOpen}>
            <div  className="small text-uppercase">
                {__( props.util.title )}
            </div>
        </Button>
        <ScalableDialog
            dialogSize={ DIALOG_SIZE.LARGE }
            isOpen={ isOpen }
            onClose={ onOpen }
        >
            <div className="w-100 h-100 flex-centered">
                { props.util.component }
                <Button
                    className="position-absolute top right m-1"
                    intent={Intent.DANGER}
                    icon="cross"
                    onClick={ onOpen }
                />
            </div>
        </ScalableDialog>
    </>
}
export default ExtendedUtilForm