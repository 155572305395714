import { Role } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_ALL_ROLES_ACTION, IMPORT_USERS_ROLES_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { ILand } from "@/modules/pe-fest-module/data/interfaces"
import { Button, Callout, Collapse } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"

/* Protopia Ecosystem component */
export interface IExportUserRolesFormProps {
   
}
const ImportUserRolesForm = (props: IExportUserRolesFormProps): JSX.Element => {
    const { landId } = useParams() 
    const [ land, setLand ] = useState<ILand>()
    const [ alloles, setAllRoles ] = useState<Role[]>([])
    const [ roles, setRoles ] = useState<Role >( )
    useEffect(() => {
        actions(GET_ALL_ROLES_ACTION, {})
            .then(res => {
                setAllRoles( res )
            })
    }, [])
    const onLand = (value: ILand) => { 
        setLand(value)
    }
    const onRoles = ( value: Role ) => { 
        setRoles( value )
    }
    const onSend = () => {
        actions(IMPORT_USERS_ROLES_ACTION,{ landId, input: { land_id: land?.id, role: roles } })
            .then(res => {
                AppToaster.show({
                    message: "Users roles update succsessfully."
                })
            })
    }
    return <div className='pe-export-user-roles-form-container small-container '>
        <Callout className=" mb-4">
            <div className="descr text-force-contrast opacity_5">
                {__("This utility assigns all Users the roles they have in other Lands.")}
            </div>
        </Callout>
        <div className=" mt-4 mb-2 text-uppercase ">
            { __( "Choose Land for import users's roles" ) }
        </div>
        <div className="container">
            <FieldInput
                type={ SCALAR_TYPES.EXTERNAL }
                value={ land }
                component="Land"
                editable
                onChange={ onLand }
                placeholder="Select Land"
            />
        </div>
        <Collapse isOpen={!!land?.id} className="w-100"> 
            <div className="py-3 "> 
                <div className=" mt-4 mb-2 text-uppercase ">
                    { __( "Set role." ) }
                </div>
                <div className="small">{__("All users who own the selected role in the source Land will receive it here too.")}</div>
                <div className="container">
                    <FieldInput
                        editable
                        multiple={false}
                        value={ { _id:roles, title: roles } }
                        values={ alloles.map(r => ({ _id:r, title: r })) }
                        type={ SCALAR_TYPES.TAGS }
                        onChange={onRoles}
                    />
                </div>
                
            </div>
        </Collapse>
        <Collapse isOpen={!!land?.id && !!roles} className="w-100"> 
            <div className="py-3">
                <Button intent="danger" large onClick={onSend}>
                    {__("Start import just now!")}
                </Button>
            </div>
        </Collapse>
    </div>
}
export default ImportUserRolesForm