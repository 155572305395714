import { IProjectMilestone } from '@/modules/pe-fest-module/data/interfaces'
import { ID } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { Icon } from '@blueprintjs/core' 

interface IMilestoneButtonProps {
    currentMilestoneID      : ID
    setCurrentMilestoneID   : ( id: ID ) => void 
    item                    : IProjectMilestone
    isCompact               : boolean
    i                       : number
} 
/*
    Кнопка переключения этапов на панели этапов Проекта (участника)
*/
const MilestoneButton = ( props : IMilestoneButtonProps) : JSX.Element => { 
    let labelColor = "", labelClass = ""
    const { milestone } = props.item 
    switch( milestone.is_auto_rait )
    {
        case true:
            labelColor = "text-dark-success"
            labelClass = "milestone-label-success"
            break
        case false:
        default:
            labelColor = "text-dark-primary"
            labelClass = "milestone-label-primary"
    }
    return <div 
        className={`milestone-button ${ labelClass } ${ props.currentMilestoneID !== milestone.id  && props.isCompact ? "px-3" : "" } ` + (props.currentMilestoneID === milestone.id ? "active" : "") }
        onClick={() => props.setCurrentMilestoneID(milestone.id)}
    >
        <span className={`milestone-button-label ${labelColor}`}>
            { props.currentMilestoneID !== milestone.id  && props.isCompact ? props.i + 1 : milestone.title }
        </span> 
        <Tik {...props} />
    </div> 
} 

export default MilestoneButton

const Tik = (props:IMilestoneButtonProps): JSX.Element => {
    if(
        props.item.milestone.is_attachments_required &&
        !props.item.attachments.length
    ) {
        return <> 
            <Icon 
                icon="error" 
                color="#c23030" 
                className='ml-2 opacity_100 hint hint--top'
                data-hint={__("Attachments must be registered")}
            />
        </>
    }
    else {
        return !props.isCompact ||  !!parseFloat( props.item.averageRating )
            ? 
            <span className='ml-2 lead'>
                { props.item.averageRating }
            </span>
            :
            <></>
    }
}