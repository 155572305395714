import { useToposStore } from "../../data/store"
import ExtendedUtilForm from "./ExtendedUtilForm"

/* Protopia Ecosystem component */
export interface IExtendedUtilsFormProps {
   
}
const ExtendedUtilsForm = (props: IExtendedUtilsFormProps): JSX.Element => {
     const eventExtendedUtils: any = useToposStore.getState().eventExtendedUtils || {}
    
    return <div className='pe-extended-utils-form-container overflow-y-auto'>
        {
            Object.keys(eventExtendedUtils).map((key: string ) => {
                return <ExtendedUtilForm util={eventExtendedUtils[key]} key={key} /> 
            })
        }
    </div>
}
export default ExtendedUtilsForm