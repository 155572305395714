import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { __, sprintf } from "@/libs/utilities"
import _cases from "@/libs/utilities/__cases"
import { Button, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { useParams } from "react-router"
import { IGanre, IPEFestSchool, ITrack } from "../../data/interfaces"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { actions } from "../../data/actions"
import { INERT_PACK_PROJETCS_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */ 
const PackProjectCreateForm = ( ): JSX.Element => {
    const {landId} = useParams()
    const [title, setTitle] = useState<string>( __("Project") ) 
    const [count, setCount] = useState<number>( 10 ) 
    const [track, setTrack] = useState<ITrack>()
    const [ganre, setGanre] = useState<IGanre[]>([])
    const [school, setSchool] = useState<IPEFestSchool[]>([])
    const [isLoad, setIsLoad] = useState<boolean>(false)

    const onCount = (value: number) => { 
        setCount(value)
    }

    const onCreate = () => {
        setIsLoad(true)
        actions( INERT_PACK_PROJETCS_ACTION, {
            land_id: landId,
            input: {
                title, 
                count,
                track: track?.id ? track.id : -1,
                ganres: ganre.map(g => g.id),
                schools: school.map(s => s.id)
            }
        } )
        .then( resp => setIsLoad(false) )
    } 
    return <div className='small-container '>
        <div className={`container small-container  ${isLoad ? "opacity_5 untouchble" : ""}`}>
            <div className=" mt-4 mb-2 text-uppercase ">
                {__("Package create unnamed Projects")}
            </div>
            <FieldInput
                editable
                type={SCALAR_TYPES.NUMBER}
                min={0}
                max={100}
                stepSize={1}
                labelStepSize={25}
                value={count}
                onChange={ onCount }
                landId={ landId } 
            /> 
            <FieldInput
                editable
                type={SCALAR_TYPES.STRING} 
                value={title}
                placeholder={__("Set title prefix")}
                onChange={ setTitle }
                landId={ landId } 
            /> 
            
            <div className="row flex-centered w-100 pt-2">
                <ConfirmButton 
                    buttonMinimal={ !count } 
                    buttonFill 
                    buttonLabel={ sprintf( __("Start package create %s"), `${count} ${_cases(count, "projects")}` ) }
                    yesLabel={ sprintf( __("Start package create %s"), `${count} ${_cases(count, "projects")}` ) }
                    noLabel={__("Close")}
                    buttonDisabled={ !count } 
                    buttonIntent={ !count ? Intent.NONE : Intent.DANGER } 
                    onConfirm={ onCreate } 
                >
                    <div className="p-4">
                        <FieldInput
                            editable
                            type={SCALAR_TYPES.EXTERNAL} 
                            component={"PEFestTrack"}
                            value={track}
                            onChange={ setTrack }
                            landId={ landId } 
                            placeholder={__("Choose Track")}
                            title={__("Choose Track")}
                        /> 
                        <FieldInput
                            editable
                            type={SCALAR_TYPES.EXTERNAL_ARRAY} 
                            component={"PEFestGanre"}
                            value={ ganre }
                            onChange={ setGanre }
                            landId={ landId } 
                            placeholder={__("Choose Ganre")}
                            title={__("Choose Ganre")}
                        />
                        <FieldInput
                            editable
                            type={SCALAR_TYPES.EXTERNAL_ARRAY} 
                            component={"PEFestSchool"}
                            value={ school }
                            onChange={ setSchool }
                            landId={ landId } 
                            placeholder={__("Choose education organization")}
                            title={__("Choose education organization")}
                        />
                    </div>
                </ConfirmButton>
            </div>
        </div>
    </div>
}
export default PackProjectCreateForm