import { Button, Collapse, Icon, Tag } from "@blueprintjs/core"
import { ICritery, IMilestone } from "../../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import CriteryAttachments from "./CriteryAttachments"
import Moment from "react-moment"
import { POST_STATUS } from "@/libs/interfaces/layouts"
import { __, __cases } from "@/libs/utilities"
import getCriteryMaxRating from "../../data/utils/getCriteryMaxRating"

/* Protopia Ecosystem component */
export interface IMilestoneProps {
   isOpen:boolean
   item: IMilestone
   setIsOpen: ( item: IMilestone) => void
}
const Milestone = (props: IMilestoneProps): JSX.Element => {
    const criteries: ICritery[] = useDataStore((state: any) => state.PEFestCritery || [])
    return <>
        <Button 
            minimal={ !props.isOpen } 
            fill 
            disabled={ props.isOpen && false } 
            alignText="left" 
            onClick={() => props.setIsOpen( props.item )}
            className={ props.item.post_status === POST_STATUS.PUBLISH ? "" : " text-secondary" } 
        >
            <div className=" short-container">
                <div className="row d-flex justify-content-between aligh-items-center">
                    <div className={`col-md-8 lead transition-duration-300 d-flex  justify-content-start align-items-center ${ props.isOpen ? "h-70" : "h-25" }`}>
                        <div> { props.item.order }. </div>
                        <Icon icon={props.item.post_status === POST_STATUS.PUBLISH ? "blank" : "eye-off" } className="px-3" />
                        <div> { props.item.title } </div> 
                    </div>                    
                    {
                        (props.item.start_date || props.item.finish_date) 
                            ?
                            <>
                                {
                                    !!props.item.start_date && <div className="col-md-2 d-flex align-items-center justify-content-end">
                                        <Tag round className="">
                                            <Moment locale="ru" format="D MMMM YYYY">
                                                {Number(props.item.start_date!) * 1000}
                                            </Moment>
                                        </Tag> 
                                    </div>
                                } 
                                {
                                    !!props.item.start_date &&  <div className="col-md-2 d-flex align-items-center justify-content-end">
                                        <Tag round className="">
                                            <Moment locale="ru" format="D MMMM YYYY">
                                                {Number(props.item.finish_date!) * 1000}
                                            </Moment>
                                        </Tag>
                                    </div> 
                                }
                            </>
                            :
                            null
                    } 
                </div> 
            </div>
            
        </Button>
        <Collapse className='w-100' isOpen={props.isOpen} >
            <div className="p-0 light-colored "> 
                <div className=" short-container">
                    <div className="small mb-0" dangerouslySetInnerHTML={{__html: props.item.post_content}} />
                </div>
                <div>
                    {
                        criteries
                            .filter( cr => cr.milestone?.id === props.item.id )
                            .sort((a:ICritery, b:ICritery) => {
                                if(a.order > b.order) {
                                    return 1
                                }
                                else if(b.order > a.order) {
                                    return -1
                                }
                                else {
                                    return 0
                                }
                            })
                            .map((cr, i: number) => {
                                const max = getCriteryMaxRating(cr) 
                                return <div className={`py-5 container-fluid strob01 ${ i !== 0 ? "border-top border-secondary " : "" }`} key={cr.id} >
                                    <div className={`short-container `}>
                                        <h4>
                                            {cr.order}. { cr.title }
                                        </h4>
                                        <div className="--small"  dangerouslySetInnerHTML={{__html: cr.post_content}} />
                                        <CriteryAttachments item={cr} />
                                        <div>
                                            <Tag round>
                                                { `${__("Up to ")}  ${max} ${__cases( max, "points" )}` }
                                            </Tag>
                                        </div>
                                    </div>
                                </div>
                            })
                    }
                </div>
            </div>
        </Collapse>
    </> 
}
export default Milestone