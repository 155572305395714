import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { useState } from "react"
import { IPEFestSchool } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { useParams } from "react-router"
import SchoolGuardianChooseWidget from "../../widgets/SchoolGuardianChooseWidget"

/* Protopia Ecosystem component */
export interface IEducationOrganizationFormProps {
   
}
const EducationOrganizationForm = (props: IEducationOrganizationFormProps): JSX.Element => {
    const {landId} = useParams()
    const [school, setSchool] = useState<IPEFestSchool>()
    return <div className=' small-container '>
        <div className='container '>
            <div className=" mt-4 mb-2 text-uppercase ">
                {__("Choose education organizations to set Guardians")}
            </div>
            <FieldInput
                editable
                type={SCALAR_TYPES.EXTERNAL}
                isMulti={false}
                value={school}
                onChange={ setSchool }
                landId={ landId }
                component="PEFestSchool"
                placeholder="Choose Education Organization"
            />
            {
                !!school && <SchoolGuardianChooseWidget
                    item={school.guardian}
                    parent={school}
                    thread="guardian"
                />
            } 
            {
                !!school && <SchoolGuardianChooseWidget
                    item={school.guardian2}
                    parent={school}
                    thread="guardian2"
                />
            }
        </div>
    </div>
}
export default EducationOrganizationForm