import { ICritery, IFestival } from "../interfaces";
import { useFestDataStore } from "../store";

export default function getCriteryMaxRating (critery: ICritery) : number {
    const festival: IFestival = useFestDataStore.getState().festival 

    const maxRating: number = festival.isIndependentGradeCritery 
        ? 
        !!critery.category?.max_rating
            ?
            critery.category?.max_rating
            :
            critery.max_rating 
        : 
        festival.maxRating || 5
    
    return maxRating
}